.Toastify__toast {
  font-family: "FuturaPT-Heavy";
  font-size: 16px;
  text-align: center;
  min-height: 48px;
}

.Toastify__toast--success {
  background: #47b79a;
}

.Toastify__toast--error {
  background: #d86161;
}

.Toastify__close-button {
  align-self: center;
}
